
import * as React  from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { TextField } from '@mui/material';
import LayerPublicAmianticTextInput from "./LayerPublicAmianticTextInput"
import "./LayerPublicAmiantic.css"
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  
} from "chart.js";
import 'chart.js/auto';
import {  getCenter } from "ol/extent";
//import {transform} from "ol/proj";
import axios from 'axios';
import zoomPlugin from "chartjs-plugin-zoom";
ChartJS.register(
  zoomPlugin
);
// Suponiendo que `data` es el objeto JSON que has proporcionado
const GraphTemperatureComponent = ({ data }) => {
   const options = {
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
       // title:  "Radiación (kWh/m²/mes)",
       title: {
          display: true,
          text:  "Radiación (kWh/m²/mes)",
          //fontColor: "#131313",
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text:  "Temperatura ºC",
         // fontColor: "#131313",
        },
       
        grid: {
          drawOnChartArea: false, // Solo dibujar la grilla para el eje Y principal
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      zoom: {
        zoom: {
          // drag: {
          //   enabled: true,
          //   backgroundColor: 'rgba(225,225,225,0.3)',
          // },
          wheel: {
            enabled: true, // Deshabilita el zoom con la rueda del ratón para evitar conflictos
          },
          pinch: {
            enabled: true
          },
          mode: "x"
        },
        pan: {
          enabled: true, // Habilita el paneo (movimiento) con el arrastre del ratón
          mode: 'x', // Permite panear solo en el eje X
        },
        
        
      },
    },
  };
  const chartData = {
    labels: data.outputs.monthly.map(d => `${d.year}-${d.month.toString().padStart(2, '0')}`),
    datasets: [
      {
        label: 'Irradiación en plano horizontal (kWh/m²/mo)',
        data: data.outputs.monthly.map(d => d['H(h)_m']),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        yAxisID: 'y',
      },
      {
        label: 'Temperatura media (°C)',
        data: data.outputs.monthly.map(d => d['T2m']),
        borderColor: 'rgb(200, 0, 0)',
        backgroundColor: 'rgba(200, 0, 0, 0.2)',
        yAxisID: 'y1',
      },
      {
        label: 'Radiación solar normal (kWh/m²/mo)',
        data: data.outputs.monthly.map(d => d['Hb(n)_m']),
        borderColor: 'rgb(0, 0, 200)',
        backgroundColor: 'rgba(0, 0, 200, 0.2)',
        yAxisID: 'y',
      },
    ],
  };

  return <Line options={options} data={chartData} />;
};

const GraphAmortizationComponent = ({ area, annualSavings, n_modulos }) => {
  const initialCostPerSquareMeter = 400; // Libras
  const totalInitialCost = area * initialCostPerSquareMeter + n_modulos*150+1000;
  const maintenanceRate = 0.07; // 5% del coste inicial

  // Calcular los ahorros acumulados anualmente y los costes de mantenimiento
  const years = Array.from({ length: 15 }, (_, i) => i + 1);
  const savings = years.map(year => annualSavings * year);
  const maintenanceCosts = years.map(year => totalInitialCost * maintenanceRate * year);
  const totalCosts = years.map(year => totalInitialCost + maintenanceCosts[year - 1]);

  const data = {
      labels: years,
      datasets: [
          {
              label: 'Coste Inicial + Mantenimiento (Libras)',
              data: totalCosts,
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              fill: false,
          },
          {
              label: 'Ahorro Acumulado (Libras)',
              data: savings,
              borderColor: 'rgba(54, 162, 235, 1)',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              fill: false,
          },
      ],
  };

  const options = {
      scales: {
          y: {
              beginAtZero: true,
          },
      },
      plugins: {
          tooltip: {
              enabled: true,
          },
      },
  };

  return (
      <div>
          <h2>Coste Inicial vs Amortización Energética</h2>
          <Line data={data} options={options} />
      </div>
  );
};




var InfoField = ({
  label,
  _data,
  fixed_decimals = false,
  is_date = false,
  backgroundColor = false,
  suffix = ""}
) => {

  // console.log(_data);
  if (fixed_decimals && _data !== undefined && _data !== null) {
    //_data = _data.toFixed(fixed_decimals).toString();
    //console.log("_data - ", label, _data)
    _data = new Intl.NumberFormat("es-ES", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,

    }).format(_data).toString();
    //console.log("_data2 - ", label, _data)

  }
  if (is_date && _data !== undefined && _data !== null) {
    _data = new Date(_data).toISOString().slice(0, 10);
  }
  return (

    <LayerPublicAmianticTextInput
      style={{
        width: "90%",
        paddingLeft: "5%",
        // marginTop: "0.8rem",
        backgroundColor: backgroundColor,
      }}
      // sx={{style:{width:"100%"}}}
      // size="small"
      label={label}
      value={"" + _data + suffix}
    //readonly
    />
  );
};

export default function TabSolar({feature}){
  const [value, setValue] = React.useState(0);
  const [solarData, setSolarData] = React.useState(0);
  // const [data_processed, set_data_processed] = React.useState( JSON.parse(JSON.stringify(feature.values_)))
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const getSolarData = (f)=>{
    //  console.log("getSolarData", f)
      //const p = new Point(getCenter(feature.getGeometry().getExtent()))
      // Suponiendo que 'feature' es tu objeto OL Feature de tipo Polygon
      const geometry = feature.getGeometry();

      // Calcular el centroide del polígono
      const centroid = getCenter(geometry.getExtent());

      // Transformar las coordenadas del centroide a EPSG:4326 si es necesario
    //  console.log("centroid", centroid)
     // const lonLat = transform(centroid, 'EPSG:25830', 'EPSG:4326');
     const url = "https://api.asbestos.ai/amiantic/v1.0/api/core/amiantic/getRadiationMonthly/"+centroid[0]+"/"+centroid[1]
      //const url = "https://re.jrc.ec.europa.eu/api/v5_1/MRcalc?lat="++"&lon="+centroid[0]+"&elevation=0&pvgis_tmy=2020&outputformat=json&usehorizon=1&userhorizon=&js=1&horirrad=1&optrad=1&mr_dni=1&avtemp=1"
      
      setSolarData(false);
      const fetchData = async (url) => {
        try {
          const response = await axios.get(url, {headers:{}});
          setSolarData(response.data); // Almacenamos los datos en el estado
        } catch (error) {
          console.error("Error al obtener los datos:", error);
          // Manejo opcional del error, por ejemplo, actualizar el estado con un error
          setSolarData(false)
        }
      };
      fetchData(url);
        
    }
    React.useEffect(() => {
        if(value == 1){
          setValue(0)
        }else{
          setValue(1)
        }
        //console.log("Getting solar data")
        getSolarData(feature)
    },[feature])
    const data = feature.values_;
    let data_processed = JSON.parse(JSON.stringify(data));
    const replacements_amianto = {
      "Provincia": "Provincia",
      "Municipio": "Municipio",
     // "address": "Dirección",
      "reference": "Ref. Catastral",
      "currentUse": "Uso actual del edificio",
      "Uso":        "Uso actual del edificio",
      "beginning": "Fecha de construcción",
      "Fecha_cons":"Fecha de construcción",
     // "age": "Antigüedad",
      "a_total": "Área",
      "Tp_Amianto":"Tipo de amianto",

      "Link_catastro": "Catastro online",
      "Link_image": "Imagen del inmueble",
      "a_amianto": "Superficie de amianto estimado",
      "amianto_percentage": "Porcentaje de ocupación de amianto",

      "priority_r": "Prioridad de retirada",
      "Prioridad": "Prioridad de retirada",
      

      "Calendario": "Calendario de retirada",
      "reti_cost": "Coste de retirada",
      "mano_cost": "Coste de la mano de obra",
      
      "new_coverC": "Coste de nueva cubierta",

      "lic_new_co": "Coste de licencia de obra",
      "LiNormal_C": "Coste de licencia de obra",

      "new_basicC": "Coste de nueva cubierta básica",
      "CuBasic_C": "Coste de nueva cubierta básica",

      "lic_new_ba": "Coste de licencia de obra básica",
      "LiBasic_C":  "Coste de licencia de obra básica",

     // "status": "Estado",
      "made_by":  "Análisis realizado por",
      "Realizado":"Análisis realizado por",
      //"Superficie de amianto":
    }
    const replacements_solar = {
      "solar_energy_total" : "Energía anual producida",
      "ton_co2_avoid" : "Toneladas de CO2 evitadas al año",
      "energy_price_saved" : "Ahorro energético estimado (precios energía diciembre 2023)",
      "n_modulos" : "Módulos fotovoltáicos instalados",

      "superficie_placas" : "Área utilizada por placas solares",
      //"a_amianto": "Superficie de amianto estimado",
      "superficie_fv_percent" : "Porcentaje de área con amianto aprovechado por placas solares",
      //"solar_energy_total" : "Energía anual estimada",
      "potencia_modulos" : "Tipo de placa instalada",
      //"ratio_kWp_m2" : "Radio KWp/m²",
      "E_d" : "Media de energía diaria",
      //"Coste \u20ac/kWp" : "Coste £ / KWp",
      //"horizontal_irradiation_mean" : "Radiación solar horizontal media",
      //"direct_normal_irradiation_mean"  : "Radiación solar directa media",
      //"temperature_mean" : "Temperatura media",
      //"optimal_angle_irradiation_mean" : "Ángulo óptimo de radiación",
      //
      //"E_m" : "Media de energía mensual",
      //"E_y" : "Media de energía anual",
      //"H(i)_d" : "Irradiación solar incidente en un día específico",
      //"H(i)_m": "Irradiación solar incidente en un més específico",
      //"H(i)_y": "Irradiación solar incidente en un año específico",
      //"SD_m" : "Desviación estándar de la irradiación solar mensual",
      //"SD_y": "Desviación estándar de la irradiación solar anual",
      //"l_aoi": "Pérdidas estimadas por ángulo de incidencia",
      //"l_spec": "Pérdidas espectrales estimadas",
      //"l_tg": "Perdidas por temperatura y baja irradiación estimadas",
      "l_total": "Perdidas totales del sistema",
    }

    const links = [
      "Link_catastro",
      "Link_image"
    ]
    const units = {
      "SD_m" : " kWh/m²",
      "SD_y" : " kWh/m²",
      "l_aoi" : " %",
      "l_spec" : " %",
      "l_tg" : " %",
      "l_total" : " %",
      "H(i)_d" : " kWh/m²",
      "H(i)_m" : " kWh/m²",
      "H(i)_y" : " kWh/m²",
      "horizontal_irradiation_mean" : " kWh/m2",
      "direct_normal_irradiation_mean" : " kWh/m2",
      "temperature_mean" : " ºC",
      "optimal_angle_irradiation_mean" : " º",
      "E_d" : " kWh",
      "E_m" : " kWh",
      "E_y" : " kWh",
      "superficie_placas" : " m²",
      "solar_energy_total" : " kWh",
      "superficie_fv_percent" : " %",
      "ton_co2_avoid" : " ton",
      "energy_price_saved" : " £",
      "n_modulos" : " ",
      "potencia_modulos" : " kWp",
      "ratio_kWp_m2" : " kWp/m²",
      "Coste \u20ac/kWp" : " £/KWp",



      "a_total": " m²",
      "a_amianto": " m²",
      //"age": " años",
      "amianto_percentage": " %",
      "mano_cost": " £",
      "labour_cost": " £",
      "new_coverC": " £",
      "lic_new_co": " £",
      "new_basicC": " £",
      "lic_new_ba": " £",
      "LiBasic_C" : " £",
      "CuBasic_C" : " £",
      "LiNormal_C" : " £",
      
      "reti_cost":" £"
    }
    const is_float = [
      "SD_m",
      "SD_y",
      "H(i)_d",
      "H(i)_m",
      "H(i)_y",
      "horizontal_irradiation_mean",
      "direct_normal_irradiation_mean",
      "temperature_mean",
      "optimal_angle_irradiation_mean",
      "E_d",
      "E_m",
      "E_y",
      "superficie_placas",
      "solar_energy_total",
      "ton_co2_avoid",
      "energy_price_saved",
     // "potencia_modulos",
      "ratio_kWp_m2",
      "a_total",
      "amianto_percentage",
      "a_amianto",
      "reti_cost",
      "mano_cost",
      "labour_cost",
      "new_coverC",
      "lic_new_co",
      "new_basicC",
      "lic_new_ba",
      "LiBasic_C",
      "CuBasic_C",
      "LiNormal_C",
      
      ]
    const is_date = []
    for (const key of Object.keys(data)) {
      if (!Object.keys(replacements_amianto).includes(key) &&!Object.keys(replacements_solar).includes(key) ) {
        delete data_processed[key];
      }
    }
    // if(location.getN_REF_CATASTRAL() != null && location.getN_REF_CATASTRAL().length() >= 14) {
      const uri = "https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCListaBienes.aspx?rc1=";
      let catastroUrl = "";
      let catastroImageUrl = "";
      if(Object.keys(data).includes("Link_catastro")){
        catastroUrl= data["Link_catastro"]
      }else if(Object.keys(data).includes("reference")){
        const rc1 = data["reference"].substring(0, 7);
        const rc2 = data["reference"].substring(7, 14);
        catastroUrl = uri + rc1 + "&rc2=" + rc2;

        data_processed["Link_catastro"] = catastroUrl;

      }
      if(Object.keys(data).includes("reference")){
        catastroImageUrl= "https://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral="+data_processed["reference"];
        data_processed["Link_image"] = catastroImageUrl
      }

    return feature ? <div style={{display:"flex",  flexDirection:"column",width:"100%", flex:"1 1 550px", maxHeight:"70vh", overflowY:"auto", paddingLeft:"1rem", paddingTop:"1rem"}}>    <TabContext value={value} >
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
     <TabList onChange={handleChange} aria-label="Información de la localización">
      <Tab label="Amianto" value={0} />
      <Tab label="Solar" value={1} />
    </TabList>
  </Box>
  <TabPanel value={0}><div>
  {Object.keys(replacements_amianto).map(key => {
          let value ="";
          if(key in data_processed && data_processed[key] != "null" && data_processed[key] != null && data_processed[key] != false){
            value = data_processed[key];
          }else{
            return <></>
          }
        
          let suffix = "";
          if(Object.keys(units).includes(key)){
            suffix=units[key];
          }
          if(key == "amianto_percentage"){
            if("a_amianto" in data_processed && "a_total" in data_processed){
              data_processed["amianto_percentage"] = data_processed["a_amianto"] / data_processed["a_total"]
              value = data_processed["amianto_percentage"]
            }else{
              return
            }
          }
          if(key == "made_by"){
            value = "AGFOREST"
          }
          return <InfoField 
          label={replacements_amianto[key] }
          _data={value}
          fixed_decimals={is_float.includes(key)}
          is_date={is_date.includes(key)}
          backgroundColor={false}
          suffix={suffix}

          
          />
        })}
  </div></TabPanel>
  <TabPanel value={1}><div>
  {Object.keys(replacements_solar).map(key => {
          let value ="";
          if(key in data_processed){
            value = data_processed[key];
          }
        
          let suffix = "";
          if(Object.keys(units).includes(key)){
            suffix=units[key];
          }
          
          return <InfoField 
          label={replacements_solar[key] }
          _data={value}
          fixed_decimals={is_float.includes(key)}
          is_date={is_date.includes(key)}
          backgroundColor={false}
          suffix={suffix}

          
          />
        })}
      {solarData && data_processed.energy_price_saved && data_processed.superficie_placas && data_processed.n_modulos?<GraphAmortizationComponent area={data_processed.superficie_placas} annualSavings={data_processed.energy_price_saved} n_modulos={data_processed.n_modulos} />:<></>}
      {solarData && solarData.outputs && solarData.outputs.monthly?<GraphTemperatureComponent data={solarData} />:<></>}
  </div></TabPanel>
  </TabContext></div>:<></>

}