import ReactDOM from 'react-dom';
import React from 'react';
import OpenLayersMap from './OpenLayersMap';

class OpenLayersMapWebComponent extends HTMLElement {

  connectedCallback() {
    let mustShowOnClickFeature         = this.getAttribute('data-must-show-on-click-feature');
    let isDrawEnabled                  = this.getAttribute('data-is-draw-enabled');
    let assetsBasePath                 = this.getAttribute('data-assets-path')
    let isAdressSearchBarEnabled       = this.getAttribute('data-is-adress-search-bar-enabled')
    let isGetCoordinatesOnClickEnabled = this.getAttribute('data-is-get-coordinates-on-click-enabled')
    let isDrawEnabledOnStartUp         = this.getAttribute('data-is-draw-enabled-on-startup')
    let isGeolocationEnabled           = this.getAttribute('data-is-geolocation-enabled')
    const serializedGeojsons           = this.getAttribute('data-geojsons');
    let layersSelected                 = this.getAttribute('data-layer-style')
    let ineCode                        = this.getAttribute("data-ine-code")
    console.log("OpenLayersMapWebComponent values:",mustShowOnClickFeature,mustShowOnClickFeature == "true", isDrawEnabled,isDrawEnabled=="true" )

    isDrawEnabled = isDrawEnabled == "true" ? true : false;
    mustShowOnClickFeature = mustShowOnClickFeature == "false" ? false : true;
    isAdressSearchBarEnabled = isAdressSearchBarEnabled == "true" ? true : false;
    isGetCoordinatesOnClickEnabled = isGetCoordinatesOnClickEnabled == "true" ? true : false;
    isDrawEnabledOnStartUp = isDrawEnabledOnStartUp == "true" ? true : false;
    isGeolocationEnabled = isGeolocationEnabled == "true" ? true : false;
    layersSelected = layersSelected != undefined ? layersSelected : "amianticPublic";
    ineCode = ineCode != undefined ? ineCode : null;
    if(assetsBasePath == undefined || assetsBasePath ==  ""){
      assetsBasePath=false;
    }
    console.log("OpenLayersMapWebComponent values:",mustShowOnClickFeature, isDrawEnabled )

    //console.log("OpenLayersMapWebComponent",serializedGeojsons )
    ReactDOM.render(
      <OpenLayersMap 
        geojsons={serializedGeojsons} 
        isDrawEnabled={isDrawEnabled} 
        mustShowOnClickFeature={mustShowOnClickFeature} 
        assetsBasePath={assetsBasePath}
        isAdressSearchBarEnabled={isAdressSearchBarEnabled}
        isGetCoordinatesOnClickEnabled={isGetCoordinatesOnClickEnabled}
        isDrawEnabledOnStartUp={isDrawEnabledOnStartUp}
        isGeolocationEnabled={isGeolocationEnabled}
        layersSelected={layersSelected}
        ineCode={ineCode}
      />,
      this
    );
  }
}

export default OpenLayersMapWebComponent;